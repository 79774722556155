<template>
  <div class="scroll-smooth">
    <section
        v-if="status || Object.keys(offer).length <= 0"
        class="empty-state section"
    >
      <div class="wrapper">
        <EmptyState
            :btnText="`${$t('request.empty_state.button')}`"
            :text="`${$t('request.empty_state.description')}`"
            :title="`${$t('request.empty_state.title')}`"
            :use-link="true"
            class="mt-8"
            icon-class="bx bx-time"
            link-path="/account/trips"
        />
      </div>
    </section>

    <div v-else class="offer">
      <section id="gallery">
        <div class="wrapper">
          <Gallery
              :mainPhoto="photosInItinerary[0] || placeholder"
              :showCalltoAction="photosInItinerary.length !== 0"
              :sidePhotoTop="photosInItinerary[1] || placeholder"
              :sidePhotoBottom="photosInItinerary[2] || placeholder"
              @click.native="openLightBox(true, photosInItinerary)"
          />
        </div>
      </section>
      <div class="section section-details">
        <div class="wrapper">
          <h2 class="section-title mt-12">
            {{ offer.trip_title }}
          </h2>
          <div class="section-tags">
            <div class="destination">{{ offer.trip_destination }}</div>
            <div class="px-3 divider">|</div>
            <div class="travelers">
              {{
                $tc("offer.adults", offer.trip_adults, {
                  count: offer.trip_adults,
                })
              }}{{
                $tc("offer.children", offer.trip_children, {
                  count: offer.trip_children,
                })
              }}
            </div>
            <div class="px-3 divider">|</div>
            <div class="duration">
              {{
                $tc("offer.days", daysInDates, {
                  count: daysInDates,
                })
              }}
            </div>
          </div>
          <div class="flex justify-between mt-6">
            <span class="font-sans text-sm font-semibold text-black-base"
            >{{ $t("offer.reference_nr") }} {{ offer.trip_request_id }}</span
            >
            <div class="total-price">
              <div class="text-sm label text-black-lighter">{{ $t("offer.total_price") }}</div>
              <div v-if="isBase" class="text-lg font-semibold">
                {{ baseTotalPrice| currency(baseCurrency, currency, rates[`${baseCurrency}_${currency}`])}}
              </div>
              <div v-else class="text-lg price">
                {{ totalPrice | currency(currency)}}
              </div>
               <div v-if="isBase" class="text-base price text">
                {{ baseTotalPrice | currency(baseCurrency) }}
              </div>
            </div>
          </div>
          <Banner 
              type="warning" 
              :description="`${$t('page.trips.banner.currencyInfo.description')}`" 
              :title="`${$t('page.trips.banner.currencyInfo.title')}`" 
              class="my-6 w-full" 
            />
        </div>
      </div>
      <AgencyBlock
          v-if="!loading"
          :photo="agent.profile_photo"
          :firstName="agent.first_name"
          :lastName="agent.last_name"
          :agencyName="agent.first_name | agencyGenitive"
          :rating="rating"
          :reviews="reviews.length"
          :loading="loading"
      />
      <section id="itinerary" class="section">
        <div class="wrapper">
          <h2 class="section-title">{{ $t("offer.itinerary.title") }}</h2>
          <DayBlock
              v-for="(itinerary, index) in trip_offer"
              :key="index"
              :itinerary="itinerary"
              :title="`${$t('offer.itinerary.day')} ${index + 1}${itinerary.title ? ': ' + itinerary.title : ''}`"
              @showPhotos="showAccommodationPhotos"
          />
        </div>
      </section>
      <PricingBlock :offer="offer"/>
      <CancellationBlock :cancellationPolicy="offer"/>
      <ReviewBlock
          v-if="!loading"
          class="mt-8"
          :rating="rating"
          :reviews="reviews"
          :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import EmptyState from "@/components/EmptyState";
import Gallery from "@/components/gallery/Gallery";
import DayBlock from "@/views/trips/trip-details/blocks/DayBlock";
import PricingBlock from "@/views/trips/trip-details/blocks/PricingBlock";
import CancellationBlock from "@/views/trips/trip-details/blocks/CancellationBlock";
import { mapGetters, mapActions } from "vuex";
import Banner from "../../../components/banners/Banner.vue";
import ReviewBlock from "./blocks/ReviewBlock.vue";
import AgencyBlock from "./blocks/AgencyBlock.vue";
import api from "@/api";
import {
  addDays,
  checkIfDatesAreEqual, generatedArrayOfDatesFromDateAndDuration,
  generatedArrayOfDatesFromDates,
  getNumDaysFromString,
  getStartDate
} from "@/shared/dateHandler.js";

export default {
  components: {
    CancellationBlock,
    DayBlock,
    Banner,
    EmptyState,
    AgencyBlock,
    Gallery,
    PricingBlock,
    ReviewBlock,
  },
  data() {
    return {
      placeholder: {
        img: require("@/assets/img/placeholders/placeholder.png"),
      },
      region: "en-CH",
      acc: "",
      loading: true,
      reviews: [],
      rating: undefined,
    };
  },
  computed: {
    ...mapGetters("user", ["offer", "agent", "pendingTrips"]),
    ...mapGetters({
      rates: "exchange/rates",
    }),
    app_file_base_path() {
      return process.env.VUE_APP_FILE_BASE_PATH;
    },
    daysInDates() {
      return getNumDaysFromString(this.offer.trip_dates);
    },
    photosInItinerary() {
      const itineraries = JSON.parse(this.offer.trip_itinerary).map(
          (itinerary) => itinerary.experiences
      );
      const photos = itineraries.map((itinerary) =>
          itinerary.map((photo) => photo.photo.split(","))
      );
      const soo = photos.flat(2);

      let a = [];
      soo.forEach((e) => {
        if (e) {
          let array = e.split("/"); // Split image path and put splits into array
          a.push({
            img: `${this.app_file_base_path}/${e}`,
            alt: this.replace(array[4]), //Check if e is available and access 5th array element
          });
        }
      });
      return a;
    },
    accommodations() {
      return JSON.parse(this.offer.trip_accommodation);
    },
    trip_offer() {
      return this.setAccommodations();
    },
    status() {
      let trip_request_id = this.offer.trip_request_id;
      let status;
      this.pendingTrips.forEach((request) => {
        if (request.id === trip_request_id)
          status = request.trip_request_status;
      });

      return status === "open" || status === "changes" || status === "draft";
    },
    isBase() {
      return parseInt(this.offer.base_trip_total_price) ? true : false;
    },
    currency() {
      return this.offer.trip_currency;
    },
    baseCurrency() {
      return this.offer.base_trip_currency;
    },
    totalPrice() {
      return this.offer.trip_total_price;
    },
    baseTotalPrice() {
      return this.offer.base_trip_total_price;
    },
    baseAdultPrice() {
      return this.offer.base_trip_price_per_adult;
    },
    baseChildPrice() {
      return this.offer.base_trip_price_per_child;
    },
    baseSingleRoomPrice() {
      return this.offer.base_trip_single_room_price;
    },
    adultPrice() {
      return this.offer.trip_price_per_adult;
    },
    childPrice() {
      return this.offer.trip_price_per_child;
    }
  },
  methods: {
    ...mapActions("exchange", ["setCurrencyRates"]),
    getRating(reviews) {
      let amountOfReviews = reviews.length;
      let sum = reviews.reduce(
          (accumulator, review) => accumulator + parseInt(review.rating),
          0
      );
      let average = sum / amountOfReviews;
      average = Math.round(average * 10) / 10;
      return parseFloat(average);
    },
    sortByDateAsc(a, b) {
      a = new Date(a.updated_at);
      b = new Date(b.updated_at);
      return a > b ? -1 : a < b ? 1 : 0;
    },
    openLightBox(boolean, photos) {
      this.$emit("openLightBox", {boolean: true, photos: photos});
    },
    goToRequest() {
      this.$router.push("/account/trips");
    },
    getTotalDays() {
      return getNumDaysFromString(this.offer.trip_dates);
    },
    getStartDate() {
      let dates = this.offer.trip_dates;
      let startDate = getStartDate(dates);
      return startDate.format('ddd, D MMM YYYY');
    },
    setDates() {
      //Create array with itinerary dates
      let startDate = this.getStartDate();
      let totalDays = this.getTotalDays();
      let arr = [];
      for (let i = 0; i < totalDays; i++) {
        let newDate = startDate;
        arr.push({date: newDate});
        startDate = addDays(startDate).format('ddd, D MMM YYYY');
      }
      return arr;
    },
    setItinerary() {
      let itinerary = JSON.parse(this.offer.trip_itinerary);
      let obj = generatedArrayOfDatesFromDates(this.offer.trip_dates);
      obj.forEach((dateItem) => {
        const currentItem = itinerary.find(i => checkIfDatesAreEqual(i.fullDate, dateItem.date));
        if (currentItem) {
          dateItem.id = currentItem.id;
          dateItem.title = currentItem.title;
          dateItem.experiences = currentItem.experiences;
        }

      });
      return obj;
    },
    setAccommodationToDates(
        current_date,
        dates_array,
        accommodation,
        itinerary_obj
    ) {
      for (let i = 0; i < dates_array.length; i++) {
        let date = dates_array[i];
        if (checkIfDatesAreEqual(date, current_date)) {
          itinerary_obj.accommodation = accommodation;
        }
      }
    },
    getCurrentAccommodationDates(days, checkin_date) {
      let arr = [];
      let startDate = getStartDate(checkin_date).format('ddd, D MMM YYYY');
      //Get dates of the current accommodation
      if (days >= 1) {
        for (let j = 0; j < days; j++) {
          let date = startDate;
          arr.push(date);
          startDate = addDays(startDate).format('ddd, D MMM YYYY');
        }
      }

      return arr;
    },
    setAccommodations() {
      let accommodations = JSON.parse(this.offer.trip_accommodation);
      let itinerary = this.setItinerary();
      accommodations.forEach(accommodation => {
        let dates = generatedArrayOfDatesFromDateAndDuration(accommodation.fullCheckinDate, accommodation.nights);
        itinerary.forEach(itinerary => {
          dates.forEach(date => {
            if (checkIfDatesAreEqual(date.date, itinerary.date)) {
              itinerary.accommodation = accommodation;
            }
          })
        })
      })

      return itinerary;
    },
    showAccommodationPhotos(paths) {
      let routes = paths.split(",");
      let arr = [];
      for (let route of routes) {
        let path_split = route.split("/");
        arr.push({
          img: `${this.app_file_base_path}/${route}`,
          alt: path_split[4],
        });
      }
      this.openLightBox(true, arr);
    },
    replace(str) {
      return str.replace(/-/g, " ");
    },
  },
  async mounted() {
    await this.setCurrencyRates();
    this.reviews = [
      ...(await api
          .getPartnerReviews(this.agent.id)
          .then((res) => {
            if (res.data.reviews !== undefined) {
              this.loading = false;
              this.rating = this.getRating(res.data.reviews);
              return res.data.reviews.sort(this.sortByDateAsc);
            } else {
              this.loading = false;
              return [];
            }
          })
          .catch((err) => console.log(err))),
    ];
  },
};
</script>

<style scoped>
.section-tags {
  @apply flex mt-2 text-sm text-black-lighter;
}

.section {
  @apply px-4 pt-6 pb-6;
}

.section-details {
  @apply pt-0;
}

.section-container {
  @apply px-4 sm:px-0;
}

.section-title {
  @apply font-sans font-semibold text-black-base text-2xl;
}

.wrapper {
  @apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.total-price {
  @apply font-sans text-right;
}
</style>
