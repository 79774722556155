<template>
  <section class="section" id="pricing">
    <div class="wrapper">
      <h2 class="section-title">{{ $t("offer.pricing.title") }}</h2>
      <div class="mt-2 pricing">
        <PricingBlocks 
          :adult-price="adultPrice" 
          :child-price="childPrice" 
          :children="offer.trip_children"
          :single-room-price="offer.trip_single_room_price" 
          :single-rooms="offer.trip_single_rooms"
          :currency="offer.trip_currency" 
          :base-currency="baseCurrency" 
          :base-single-room-price="baseSingleRoomPrice"
          :base-child-price="baseChildPrice" 
          :base-adult-price="baseAdultPrice" 
          :is-base="isBase" 
        />
        <h3 class="section-sub-title">{{ $t("offer.pricing.title") }}</h3>
        <div class="mt-4">
          <!-- ADULT PRICE -->
          <!-- Show if base currency equals trip currency-->
          <div v-if="isBase" class="flex justify-between items-center text-black-base">
            <div class="calculation">{{ baseAdultPriceFormatted }} × {{ adults }}</div>
            <div class="result">
              {{ baseTotalAdultPriceFormatted}}
            </div>
          </div>
          <!-- Show if base currency does not equal trip currency-->
          <div v-else class="flex justify-between items-center text-black-base">
            <div class="calculation">{{ adultPriceFormatted }} × {{ adults }}</div>
            <div class="result">
              {{ totalAdultPriceFormatted}}
            </div>
          </div>
          <!-- Show price in base along side trip currency when base currency does not equal trip currency-->
          <div v-if="!isBase" class="flex justify-between items-center text-sm text-black-lighter">
            <div class="calculation">{{ baseAdultPriceFormatted }} × {{ adults }}</div>
            <div class="result">
              {{ baseTotalAdultPriceFormatted }}
            </div>
          </div>
        </div>

        <Divider class="w-full my-4" />

        <div v-if="offer.trip_children > 0">
          <!-- Show if base currency equals trip currency-->
          <div v-if="isBase" class="flex justify-between items-center text-black-base">
            <div class="calculation">{{ baseChildPriceFormatted }} × {{ children }}</div>
            <div class="result">
              {{ baseTotalChildPriceFormatted }}
            </div>
          </div>
          <!-- Show if base currency does not equal trip currency-->
          <div v-else class="flex justify-between items-center text-black-base">
            <div class="calculation">{{ childPriceFormatted }} × {{ children }}</div>
            <div class="result">
              {{ totalChildPriceFormatted }}
            </div>
          </div>
          <!-- Show price in base along side trip currency when base currency does not equal trip currency-->
          <div v-if="!isBase" class="flex justify-between items-center text-sm text-black-lighter">
            <div class="calculation">{{ baseChildPriceFormatted }} × {{ children }}</div>
            <div class="result">
              {{ baseTotalChildPriceFormatted }}
            </div>
          </div>
        </div>

        <Divider v-if="offer.trip_children > 0" class="w-full my-4" />

        <div v-if="totalSingleRoomPrice > 0">
          <div class="mt-4">
            <!-- Show if base currency equals trip currency-->
            <div v-if="isBase" class="flex justify-between items-center text-black-base">
              <div class="calculation">{{ baseSingleRoomPriceFormatted }} × {{ singleRooms }}</div>
              <div class="result">
                {{ baseTotalSingleRoomPriceFormatted }}
              </div>
            </div>
            <!-- Show if base currency does not equal trip currency-->
            <div v-else class="flex justify-between items-center text-black-base">
              <div class="calculation">{{ singleRoomPriceFormatted }} × {{ singleRooms }}</div>
              <div class="result">
                {{ totalSingleRoomPriceFormatted }}
              </div>
            </div>
            <!-- Show price in base along side trip currency when base currency does not equal trip currency-->
            <div v-if="!isBase" class="flex justify-between items-center text-sm text-black-lighter">
              <div class="calculation">{{ singleRoomPriceFormatted }} × {{ singleRooms }}</div>
              <div class="result">
                {{ totalSingleRoomPriceFormatted }}
              </div>
            </div>
          </div>
        </div>

        <Divider v-if="totalSingleRoomPrice > 0" class="w-full mt-4" />

        <div class="py-3">
          <!-- Show if base currency equals trip currency-->
          <div v-if="isBase" class="font-bold text-black-base flex justify-between items-center">
            <div class="total">{{ $t("offer.pricing.total") }}</div>
            <div class="result">{{ baseTotalPriceFormatted }}</div>
          </div>
          <!-- Show if base currency does not equal trip currency-->
          <div v-else class="font-bold text-black-base flex justify-between items-center">
            <div class="total">{{ $t("offer.pricing.total") }}</div>
            <div class="result">{{ totalPriceFormatted }}</div>
          </div>
          <div v-if="!isBase" class="font-semibold text-black-lighter text-sm flex justify-between items-center">
            <div class="total">{{ $t("common.totalIn") }} {{ baseCurrency }}</div>
            <div class="result">{{ baseTotalPriceFormatted }}</div>
          </div>
        </div>

        <div class="items-section-container">
          <div class="inclusions items-section">
            <h3 class="font-sans text-lg font-semibold text-black-base">
              {{ $t("offer.scope.inclusions") }}
            </h3>
            <div class="mt-2 list">
              <div v-for="(inclusion, index) in offer.trip_inclusions.split(',')" :key="index"
                class="flex items-start mb-3">
                <i class="text-lg bx bxs-check-circle inclusion-icon"></i>
                <div class="ml-2 font-sans text-black-base">
                  {{ inclusion }}
                </div>
              </div>
            </div>
          </div>
          <div class="exclusions items-section">
            <h3 class="font-sans text-lg font-semibold text-black-base">
              {{ $t("offer.scope.exclusions") }}
            </h3>
            <div class="mt-2 list">
              <div v-for="(exclusion, index) in offer.trip_exclusions.split(',')" :key="index"
                class="flex items-start mb-3">
                <i class="text-lg bx bxs-x-circle exclusion-icon"></i>
                <div class="ml-2 font-sans text-black-base">
                  {{ exclusion }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Divider from "@/components/dividers/Divider";
import PricingBlocks from "../../components/PricingBlocks.vue"
import { mapGetters } from "vuex";
import ct from 'countries-and-timezones';

export default {
  name: "PricingBlock",
  components: {
    Divider,
    PricingBlocks
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      region: "en-US",
    };
  },
  methods: {

    formatToCurrency(value, baseCurrency, quoteCurrency = false, exchangeRate = false) {

      const timezone = ct.getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
      const country = timezone.countries[0];

      const countryToRegionMap = {
        CH: 'de-CH',
        US: 'en-US',
        DE: 'de-DE',
        FR: 'fr-FR',
        GB: 'en-GB',
        BE: 'fr-BE',
      };

      const region = countryToRegionMap[country] || 'en-US';

      let price;
      const round = (value, precision = 100) => Math.floor(value * precision) / precision;

      // Ensure that currency codes are provided
      if (!baseCurrency) {
        console.warn("Base currency is required with currency style.");
        return value; // Return raw value or a fallback
      }

      if (!quoteCurrency || !exchangeRate || quoteCurrency === baseCurrency) {
        price = new Intl.NumberFormat(region, {
          style: 'currency',
          currency: baseCurrency,  // Ensure baseCurrency is defined
        }).format(value);
      } else {
        const amount = round(value * exchangeRate);
        price = new Intl.NumberFormat(region, {
          style: 'currency',
          currency: quoteCurrency,  // Ensure quoteCurrency is defined
        }).format(amount);
      }

      return price;
    },
    formatToCurrencyValue(value, baseCurrency, quoteCurrency = false, exchangeRate = false) {
      // Rounding function
      const round = (value, precision = 100) => Math.floor(value * precision) / precision;

      // Handle cases where exchange rate or quote currency are not provided
      if (!quoteCurrency || !exchangeRate || quoteCurrency === baseCurrency) {
        return round(value);
      } else {
        // Convert the value with the exchange rate and return the numeric value only
        return round(value * exchangeRate);
      }
    },
  },
  computed: {
    ...mapGetters({
      rates: "exchange/rates",
    }),
    baseCurrency() {
      return this.offer.base_trip_currency;
    },
    currency() {
      return this.offer.trip_currency;
    },
    earningFees() {
      return 1 + this.offer.trip_earning_fees / 100;
    },
    totalTravellers() {
      return this.offer.trip_adults + this.offer.trip_children;
    },
    adults() {
      let count = this.offer.trip_adults;
      return this.$tc("offer.pricing.adults", count, {
        count: count,
      });
    },
    children() {
      let count = this.offer.trip_children;
      return this.$tc("offer.pricing.children", count, {
        count: count,
      });
    },
    singleRooms() {
      let count = this.offer.trip_single_rooms;
      return this.$tc("offer.pricing.rooms", count, { count: count });
    },
    rate() {
      return this.rates[`${this.baseCurrency}_${this.currency}`];
    },
    isBase() {
      return this.baseCurrency === this.currency;
    },
    baseAdultPrice() {
      return this.offer.base_trip_price_per_adult * this.earningFees;
    },
    baseAdultPriceFormatted() {
      return this.formatToCurrency(this.baseAdultPrice, this.baseCurrency);
    },
    baseTotalAdultPrice() {
      return this.baseAdultPrice * this.offer.trip_adults;
    },
    baseTotalAdultPriceFormatted() {
      return this.formatToCurrency(this.baseTotalAdultPrice, this.baseCurrency);
    },
    adultPrice() {
      return this.formatToCurrencyValue(this.baseAdultPrice, this.baseCurrency, this.currency, this.rate);
    },
    adultPriceFormatted() {
      return this.formatToCurrency(this.adultPrice, this.currency);
    },
    totalAdultPrice() {
      return this.formatToCurrencyValue(this.adultPrice * this.offer.trip_adults, this.currency);
    },
    totalAdultPriceFormatted() {
      return this.formatToCurrency(this.totalAdultPrice, this.currency);
    },
    baseChildPrice() {
      return this.offer.base_trip_price_per_child * this.earningFees;
    },
    baseChildPriceFormatted() {
      return this.formatToCurrency(this.baseChildPrice, this.baseCurrency);
    },
    baseTotalChildPrice() {
      return this.baseAdultPrice * this.offer.trip_children;
    },
    baseTotalChildPriceFormatted() {
      return this.formatToCurrency(this.baseTotalChildPrice, this.baseCurrency);
    },
    childPrice() {
      return this.formatToCurrencyValue(this.baseChildPrice, this.baseCurrency, this.currency, this.rate);
    },
    childPriceFormatted() {
      return this.formatToCurrency(this.childPrice, this.currency);
    },
    totalChildPrice() {
      return this.formatToCurrencyValue(this.childPrice * this.offer.trip_adults, this.currency);
    },
    totalChildPriceFormatted() {
      return this.formatToCurrency(this.totalChildPrice, this.currency);
    },
    baseSingleRoomPrice() {
      return this.offer.base_trip_single_room_price;
    },
    baseSingleRoomPriceFormatted() {
      return this.formatToCurrency(this.baseSingleRoomPrice, this.baseCurrency);
    },
    baseTotalSingleRoomPrice() {
      return this.baseSingleRoomPrice * this.offer.trip_single_rooms;
    },
    baseTotalSingleRoomPriceFormatted() {
      return this.formatToCurrency(this.baseTotalSingleRoomPrice, this.baseCurrency);
    },
    singleRoomPrice() {
      return this.formatToCurrencyValue(this.baseSingleRoomPrice, this.baseCurrency, this.currency, this.rate);
    },
    singleRoomPriceFormatted() {
      return this.formatToCurrency(this.singleRoomPrice, this.currency);
    },
    totalSingleRoomPrice() {
      return this.formatToCurrencyValue(this.singleRoomPrice * this.offer.trip_single_rooms, this.currency);
    },
    totalSingleRoomPriceFormatted() {
      return this.formatToCurrency(this.totalSingleRoomPrice, this.currency);
    },
    baseTotalPrice() {
      return this.baseTotalAdultPrice + this.baseTotalChildPrice + this.baseTotalSingleRoomPrice;
    },
    baseTotalPriceFormatted() {
      return this.formatToCurrency(this.baseTotalPrice, this.baseCurrency);
    },
    totalPrice() {
      return this.totalAdultPrice + this.totalChildPrice + this.totalSingleRoomPrice;
    },
    totalPriceFormatted() {
      return this.formatToCurrency(this.totalPrice, this.currency);
    },
  }
};
</script>

<style scoped>
.inclusion-icon {
  @apply mt-1 text-green-base;
}

.exclusion-icon {
  @apply mt-1 text-red-base;
}

.items-section-container {
  @apply mt-12;
}

.exclusions {
  @apply mt-6;
}

.items-section {
  @apply w-full sm:w-calc-1/2;
}

.section-title {
  @apply mt-12 font-sans text-2xl font-semibold text-black-base;
}

.section-sub-title {
  @apply mt-6 font-sans text-xl font-semibold text-black-base;
}

.price-travelers {
  @apply inline-block p-4 mr-3 font-sans rounded-md bg-white shadow-sm border border-grey-base;
}

.price-per-traveler {
  @apply font-semibold text-black-base text-xl;
}

.price-label {
  @apply text-black-lightest text-base;
}

.pricing-table-row {
  @apply flex justify-between w-full text-black-base;
}

.section {
  @apply px-4 py-6;
}

.wrapper {
  @apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}
</style>
