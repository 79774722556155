<template>
	<section class="pricing-section" id="pricing">
		<div class="wrapper">
			<div class="price--items--container">
				<div class="price--item">
					<div class="price--text--container" property="description">
						<h3 class="price--title">
							<i class="bx bx-body bx-xs"> </i> {{ $t("common.adults") }}
						</h3>
					</div>
					<div class="price--container text-right" property="offers" typeof="Offer">
						<div class="flex items-center gap-1">
							<span v-if="isBase" class="price text-black-base font-bold text-sm">
								{{ baseAdultPrice | currency(baseCurrency) }}
							</span>
							<span v-else class="price text-black-base font-bold text-sm">
								{{ baseAdultPrice | currency(baseCurrency, currency, rates[`${baseCurrency}_${currency}`]) }}
							</span>
							<template v-if="!isBase">|
								<span class="price font-medium text-black-base text-xs">{{ baseAdultPrice | currency(baseCurrency) }}</span>
							</template>
						</div>
						<span class="price--unit" v-t="'common.per_traveller'" />
					</div>
				</div>
				<div class="price--item" v-if="children > 0">
					<div class="price--text--container" property="description">
						<h3 class="price--title">
							<i class="bx bx-child"> </i> {{ $t("common.children") }}
						</h3>
					</div>
					<div class="price--container" property="offers" typeof="Offer">
						<div class="flex items-center gap-1">
							<span v-if="isBase" class="price text-black-base font-bold text-sm">
								{{ baseChildPrice | currency(baseCurrency) }}
							</span>
							<span v-else class="price text-black-base font-bold text-sm">
								{{ baseChildPrice | currency(baseCurrency, currency, rates[`${baseCurrency}_${currency}`]) }}
							</span>
							<template v-if="!isBase">|
								<span class="price font-medium text-black-base text-xs">{{ baseChildPrice | currency(baseCurrency) }}</span>
							</template>
						</div>
						<span class="price--unit" v-t="'common.per_traveller'" />
					</div>
				</div>
				<div class="price--item" v-if="singleRooms > 0">
					<div class="price--text--container" property="description">
						<h3 class="price--title">
							<i class="bx bxs-hotel bx-xs"> </i> {{ $t("page.group_tour.section.price.single_room") }}
						</h3>
					</div>
					<div class="price--container" property="offers" typeof="Offer">
						<div class="flex items-center gap-1">
							<span v-if="isBase" class="price text-black-base font-bold text-sm">
								{{ baseSingleRoomPrice | currency(baseCurrency) }}
							</span>
							<span v-else class="price text-black-base font-bold text-sm">
								{{ baseSingleRoomPrice | currency(baseCurrency, currency, rates[`${baseCurrency}_${currency}`]) }}
							</span>
							<template v-if="!isBase">|
								<span class="price font-medium text-black-base text-xs">{{ baseSingleRoomPrice | currency(baseCurrency) }}</span>
							</template>
						</div>
						<span class="price--unit" v-t="'common.per_traveller'" />
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "PricingBlock",
	data() {
		return {
			exchangeRates: {},
		};
	},
	props: {
		isBase: {
			type: Boolean,
			default: false,
		},
		adultPrice: {
			type: Number,
			default: 1000,
		},
		baseAdultPrice: {
			type: Number,
			default: 0,
		},
		children: {
			props: Number,
			default: 0,
		},
		childPrice: {
			type: Number,
			default: 1000,
		},
		baseChildPrice: {
			type: Number,
			default: 0,
		},
		currency: {
			type: String,
			default: "USD",
		},
		baseCurrency: {
			type: String,
			default: "CHF"
		},
		baseSingleRoomPrice: {
			type: Number,
			default: 0,
		},
		singleRooms: {
			type: Number,
			default: 1,
		},
		singleRoomPrice: {
			type: Number,
			default: 0,
		}
	},
	computed: {
		...mapGetters({
			getCurrency: "global/currency",
			rates: "exchange/rates",
		}),
		totalSingleRoomPrice() {
			return this.singleRoomPrice * this.singleRooms;
		}
	},
};
</script>

<style scoped>
.section-title {
	@apply font-sans text-2xl font-semibold text-black-base;
}

.wrapper {
	@apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.price--items--container {
	@apply grid gap-4 sm:grid-cols-2 mt-6;
}

.price--item {
	@apply border border-grey-base rounded-lg p-4 shadow-sm;
	@apply flex items-center justify-between gap-8;
}

.price--title {
	@apply font-semibold text-black-base text-base whitespace-nowrap;
	@apply flex items-center gap-1;
}

.price--description {
	@apply text-xs text-black-lighter;
}

.price--container>span {
	@apply block;
}

.price--unit {
	@apply text-black-lighter text-xs font-medium text-right;
}
</style>
